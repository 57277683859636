import React from "react"
import { AnimatedSection } from "@components/shared"

import beginner from "./icons/beginner.png"
import teacher from "./icons/teacher.png"
import task from "./icons/task.png"
import searchHuman from "./icons/browser-human2.png"

import {
  targeting__attention,
  targeting__wave,
  targeting__attention__title,
  targeting__questions,
  targeting__qa,
  targeting__questions__icon,
} from "./styles/home.module.scss"

const TargetingSection = () => {
  return (
    <section className={targeting__attention}>
      <div className={targeting__questions}>
        <AnimatedSection className={targeting__qa}>
          <div className={targeting__questions__icon}>
            <img src={beginner} alt="" />
          </div>
          Jak mam rozwijać się jako początkujący programista/-stka?
        </AnimatedSection>
        <AnimatedSection className={targeting__qa}>
          <div className={targeting__questions__icon}>
            <img src={teacher} alt="" />
          </div>
          Gdzie znajdę doświadczonego kolegę, który sprawdzi mój kod?
        </AnimatedSection>
        <AnimatedSection className={targeting__qa}>
          <div className={targeting__questions__icon}>
            <img src={task} alt="" />
          </div>
          Gdzie mogę znaleźć praktyczne zadania programistyczne, które stanowią
          wyzwanie i zapewnią rozwój?
        </AnimatedSection>
        <AnimatedSection className={targeting__qa}>
          <div className={targeting__questions__icon}>
            <img src={searchHuman} alt="" />
          </div>
          Gdzie znajdę osoby chętne do współpracy przy projektach?
        </AnimatedSection>
      </div>

      <div className={targeting__wave}>
        <svg
          id="wave"
          data-name="wave"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 1212.7"
        >
          <path
            class="cls-1"
            d="M0,658.61c107.3,2.35,269.52-29.35,269.52-29.35,319.31-82.17,349.83-263,523.57-423.78S1200,37.57,1200,37.57V627.3c-.11,2.15-1.52,3.56-2.92,5.47-1,1.42-.26.64-3,5.22q-1.85,3-3.78,5.93c-.77,1.15-1.56,2.29-2.36,3.41-10,14-21.75,25-35.61,32.41C817.74,858.17,850.61,943.87,620.52,1114.09S0,1246.74,0,1246.74H0"
            transform="translate(0 -37.57)"
            fill="#3ec5d8"
          />
        </svg>
      </div>
    </section>
  )
}

export default TargetingSection

import React, { Component } from 'react'

const cleanPrice = (price) => {
	const _p = price.replace(' ','')
	return parseFloat(_p.split('&nbsp;')[0])
}

const Prices = ({ sale, regularValue, value })=>{
	return (<div>
	<small>{value}PLN</small>
	{sale && <span>{regularValue}PLN</span>}
</div>)
}

class AltAddToCart extends Component {
    state = {
		value: null,
		regularValue: null,
		quantity: 1,
		product_id: null,
		variation_id: null,
		variant: null,
		sale: false,
		price: '',
		regularPrice: '',
		unit: 'PLN',
		name: '',
		slug: '',
    }
    
    onInit = () => {
		if (this.props.salePrice) {
			this.setState({
				product_id: this.props.productId,
				name: this.props.name,
				slug: this.props.slug,
				sale: true,
				price: cleanPrice(this.props.salePrice),
				regularPrice: cleanPrice(this.props.regularPrice),
				regularValue: cleanPrice(this.props.regularPrice),
				value: cleanPrice(this.props.salePrice),
				quantity: 1,
			})
		} else {
			this.setState({
				product_id: this.props.productId,
				name: this.props.name,
				slug: this.props.slug,
				price: cleanPrice(this.props.regularPrice),
				regularPrice: cleanPrice(this.props.regularPrice),
				regularValue: cleanPrice(this.props.regularPrice),
				value: cleanPrice(this.props.regularPrice),
				quantity: 1,
			})
		}
	}
	componentDidMount() {
		this.onInit()
		 
    }
    
    render() {
		const { quantity, value, regularValue, sale } = this.state
		

        return (
            <div>
            <a 
            onClick={() => {
                this.props.onAddToCart(this.state)
                this.onInit()
            }}>Dodaj do koszyka</a>
            </div>
        )
    }
}

export default AltAddToCart
import React, { useState } from "react"
import { AnimatedLink, AnimatedSection } from "@components/shared"
import { Parallax } from "@components/layout"

import {
  attention__body,
  attention__wave,
  attention__body__container,
  attention__body__description,
  attention__body__title,
  attention__learn__more,
  attention__body__learn__more,
  attention__body__subtitle,
} from "./styles/attention.module.scss"

const Wave = () => {
  return (
    <div className={attention__wave}>
      <svg viewBox="0 0 1200 450">
        <path
          d="M0,450S19,128.94,252.9,247.48,590.78,456.67,919.84,441.84c327.34-14.76,274.34-227.09,280.16-229V450Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

const HomeAttentionSection = () => {
  const [learn, set] = useState(false)
  return (
    <div className={attention__body}>
      <div className={attention__body__container}>
        <Parallax>
          <section className={attention__body__description}>
            <h2 className={attention__body__title}>
              Trenuję "Gladiatorów Javascriptu"
            </h2>
            <h4 className={attention__body__subtitle}>
              Pomagam programistom JS i Pythona zdobywać nowe zaawansowane
              umiejki programistyczne po to, aby byli gotowi zdobywać rynek
            </h4>
            <AnimatedSection className={attention__body__learn__more}>
              <AnimatedLink
                className={attention__learn__more}
                to={"/gladiators/"}
              >
                Poczytaj o gladiatorach
              </AnimatedLink>
            </AnimatedSection>
            <Wave />
          </section>
        </Parallax>
      </div>
    </div>
  )
}

export default HomeAttentionSection
